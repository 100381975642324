import React, { useState, useEffect, useCallback, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.js';
import ResponsiveWaveSurfer from '../../components/audio/ResponsiveWaveSurfer';
import FullScreenVisualizer from '../../components/audio/FullScreenVisualizer';
import VisualizerLoader from './VisualizerLoader';
import {
  initializeAudio,
  initializeAudioAnalysis,
  syncPlayback,
  syncTimePosition
} from '../audio/audio-sync-fix';
import AudioDiagnosticsTool from '../audio/AudioDiagnosticsTool';
import {
  IoPlayCircle,
  IoPauseCircle,
  IoPlaySkipBack,
  IoPlaySkipForward,
  IoList,
  IoClose,
  IoDownload,
  IoRefresh,
  IoImage,
  IoExpand,
  IoContract,
  IoVolumeHigh,
  IoVolumeOff
} from 'react-icons/io5';

const ListenStep = ({
  audioUrl: initialAudioUrl,
  onExportAudio,
  onReset,
  onError,
  toast,
  selectedMusic = "",
  musicLibrary = [],
  setSelectedMusic = () => {},
  onApplyChanges = () => {},
  baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api.pause.site',
  isMixingAudio = false,
  onAudioLoaded = null,
  ttsKey = "",
  windowDimensions,
  mixedAudioInfo = null,
  getBestAudioUrl = null,
  centralizeAudioUrl = null,
  setIsMixingAudio = () => {}
}) => {
  // --- State Declarations ---
  const [audioUrl, setAudioUrl] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [isLoadingAudio, setIsLoadingAudio] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showWaveSurferError, setShowWaveSurferError] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [useVisualizer, setUseVisualizer] = useState(true);
  const [visualizerSize, setVisualizerSize] = useState(300);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth || 0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight || 0);
  const [zoom, setZoom] = useState(50);
  const [volume, setVolume] = useState(1.0);
  const [isMuted, setIsMuted] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [initComplete, setInitComplete] = useState(false);
  const [failedLoadAttempts, setFailedLoadAttempts] = useState(0);
  const [loadingTimedOut, setLoadingTimedOut] = useState(false);
  const [hasMixedAudio, setHasMixedAudio] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Loading your meditation audio...');
  const [mixReady, setMixReady] = useState(false);
  const [isBackgroundTTSProcessing, setIsBackgroundTTSProcessing] = useState(false);
  const [backgroundTTSProgress, setBackgroundTTSProgress] = useState(0);
  const [globalStateInitialized, setGlobalStateInitialized] = useState(false);
  const [isUsingGlobalAudio, setIsUsingGlobalAudio] = useState(false);
  const [analyzerNode, setAnalyzerNode] = useState(null);
  const [componentError, setComponentError] = useState(null);
  const [componentReady, setComponentReady] = useState(false);
  const [wavesurferInitialized, setWavesurferInitialized] = useState(false);
  const [componentInitialized, setComponentInitialized] = useState(false);
  const safeInitializeWaveSurferRef = useRef(null);

  useEffect(() => {
    // Signal that component is mounted (for transition coordination)
    const signalMounted = () => {
      if (typeof window !== 'undefined') {
        window.__listenStepMounted = true;
        
        // Clear transition flags
        window.__transitioningToListen = false;
        window.__listenStepReadyToMount = true;
        window.__listenStepMountComplete = true;
      }
      
      try {
        sessionStorage.setItem('listenStepMounted', 'true');
        sessionStorage.setItem('isTransitioningToListen', 'false');
      } catch (e) {
        console.warn("Error updating mount state in storage:", e);
      }
      
      // Mark component as initialized after a small delay
      setTimeout(() => {
        setComponentInitialized(true);
      }, 100);
    };
    
    // Defer component initialization slightly to avoid React errors
    const initTimer = setTimeout(signalMounted, 100);
    
    return () => {
      clearTimeout(initTimer);
      if (typeof window !== 'undefined') {
        window.__listenStepMounted = false;
      }
    };
  }, []);
  


  // --- Refs ---
  const mounted = useRef(true);
  const isMountedRef = useRef(true);
  const audioElementRef = useRef(null);
  const waveformContainerRef = useRef(null);
  const timelineContainerRef = useRef(null);
  const mobileWaveformContainerRef = useRef(null);
  const waveSurferRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyzerRef = useRef(null);
  const audioInitTimeoutRef = useRef(null);
  const safetyTimeoutsRef = useRef([]);
  const audioLoadTimeoutRef = useRef(null);
  const audioUrlRef = useRef(initialAudioUrl || '');
  const blobUrlsRef = useRef([]);
  const globalAudioRef = useRef(window.globalAudioState || null);
  const initializeWaveSurferRef = useRef(null);
  const lastPlayingStateRef = useRef(false);
  const initializeAudioAnalysisRef = useRef(null);
  const setupAudioElementListenersRef = useRef(null);


  const isMobile = windowWidth <= 768;
  const isFullscreenMobile = isFullscreen && isMobile;

  // --- Helper Functions ---
  

  // Toast utility
  const safeToast = useCallback((type, message, options = {}) => {
    if (!mounted.current) return null;
    try {
      if (globalAudioRef.current) {
        globalAudioRef.current.publishEvent('toast', { type, message, options, source: 'ListenStep', timestamp: Date.now() });
      }
      if (toast && typeof toast[type] === 'function') {
        return toast[type](message, options);
      } else if (typeof toast === 'function') {
        return toast(message, { ...options, type });
      }
    } catch (err) {
      console.log(`Error showing toast: ${err.message}`);
    }
    console.log(`Toast (${type}): ${message}`);
    return null;
  }, [toast]);

  // Validate URL
  const isValidUrl = useCallback((url) => {
    return !!(url && typeof url === 'string' &&
      (url.startsWith('http://') || url.startsWith('https://') || url.startsWith('blob:') || url.startsWith('data:')));
  }, []);

  // Create a silent audio blob for fallback
  const createFallbackAudioBlob = useCallback(() => {
    console.log("AUDIO PLAYER: Creating silent audio blob for fallback");
    try {
      const arrayBuffer = new ArrayBuffer(10000);
      const view = new Uint8Array(arrayBuffer);
      const silentHeader = [0xFF, 0xFB, 0x90, 0x44, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < arrayBuffer.byteLength; i += 16) {
        silentHeader.forEach((value, index) => {
          if (i + index < arrayBuffer.byteLength) view[i + index] = value;
        });
      }
      const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' });
      return URL.createObjectURL(blob);
    } catch (err) {
      console.error("AUDIO PLAYER: Error creating silent audio blob:", err);
      return "data:audio/mpeg;base64,SUQzBAAAAAAAI1RTU0UAAAAP...";
    }
  }, []);

  // Return first valid URL or fallback
  const getValidAudioUrl = useCallback(() => {
    const sources = [
      initialAudioUrl,
      audioUrl,
      globalAudioRef.current?.getBestAudioUrl?.(),
      audioUrlRef.current
    ];
    for (const url of sources) {
      if (url && isValidUrl(url)) {
        return url;
      }
    }
    console.warn("AUDIO PLAYER: No valid URL found, creating silent audio");
    return createFallbackAudioBlob();
  }, [initialAudioUrl, audioUrl, isValidUrl, createFallbackAudioBlob]);


  // Minimal setup for audio element listeners (you can customize this)
  const setupAudioElementListeners = useCallback((audioEl) => {
    if (!audioEl) return;
    audioEl.addEventListener('loadedmetadata', () => {
      console.log("AUDIO PLAYER: loadedmetadata event");
    });
    audioEl.addEventListener('error', (err) => {
      console.error("AUDIO PLAYER: Audio element error:", err, audioEl.error);
      if (audioEl.error) {
        console.error("MediaError code:", audioEl.error.code);
      }
      safeToast('error', 'Audio playback error. Trying alternative method.');
    });
    audioEl.addEventListener('timeupdate', () => {
      setCurrentTime(audioEl.currentTime || 0);
    });
    audioEl.addEventListener('play', () => {
      console.log("AUDIO PLAYER: Audio element play event");
      setIsPlaying(true);
    });
    audioEl.addEventListener('pause', () => {
      console.log("AUDIO PLAYER: Audio element pause event");
      setIsPlaying(false);
    });
    audioEl.addEventListener('ended', () => {
      console.log("AUDIO PLAYER: Audio element ended event");
      setIsPlaying(false);
      setCurrentTime(0);
      audioEl.currentTime = 0;
    });
  }, [safeToast]);


  // Create fallback audio element if needed
  const createFallbackAudioElement = useCallback((url) => {
    if (!url) {
      console.warn("AUDIO PLAYER: No URL provided for fallback audio");
      return null;
    }
    console.log("AUDIO PLAYER: Creating fallback audio element with URL:", url);
    try {
      let audioEl = audioElementRef.current;
      if (globalAudioRef.current && globalAudioRef.current._audioElement) {
        audioEl = globalAudioRef.current._audioElement;
        audioElementRef.current = audioEl;
        setIsUsingGlobalAudio(true);
      } else if (!audioEl) {
        audioEl = new Audio();
        audioElementRef.current = audioEl;
        if (globalAudioRef.current && !globalAudioRef.current._audioElement) {
          globalAudioRef.current._audioElement = audioEl;
        }
      }
      audioEl.crossOrigin = "anonymous";
      audioEl.preload = "auto";
      audioEl.src = url;
      if (!audioEl._listenersInitialized) {
        setupAudioElementListeners(audioEl);
        audioEl._listenersInitialized = true;
      }
      audioEl.load();
      audioEl.volume = volume;
      audioEl.muted = isMuted;
      if (url.startsWith('blob:')) {
        blobUrlsRef.current.push(url);
      }
      return audioEl;
    } catch (err) {
      console.error("AUDIO PLAYER: Error creating fallback audio element:", err);
      safeToast('error', 'Audio player initialization failed');
      return null;
    }
  }, [volume, isMuted, safeToast, setupAudioElementListeners]);

  // Load audio safely into WaveSurfer
  const safeLoadAudio = useCallback((url, wsInstance) => {
    if (!url || !wsInstance || !isValidUrl(url)) {
      console.warn("AUDIO PLAYER: Invalid parameters for loading audio");
      return;
    }
    
    console.log("AUDIO PLAYER: Loading URL safely:", url);
    
    // Safety check - ensure the WaveSurfer instance is still valid
    if (typeof wsInstance.load !== 'function') {
      console.warn("AUDIO PLAYER: WaveSurfer instance is not valid for loading");
      
      // Use the ref instead of direct dependency
      const newInstance = safeInitializeWaveSurferRef.current ? 
        safeInitializeWaveSurferRef.current() : null;
      
      if (!newInstance) {
        if (audioElementRef.current) {
          try {
            audioElementRef.current.src = url;
            audioElementRef.current.load();
            setAudioLoaded(true);
            setIsLoadingAudio(false);
          } catch (err) {
            console.warn("AUDIO PLAYER: Error loading audio in fallback element:", err);
          }
        }
        return;
      }
      
      wsInstance = newInstance;
      waveSurferRef.current = newInstance;
    }
    
    // Cancel any previous load operation
    try {
      if (window.__prevAbortController) {
        window.__prevAbortController.abort();
      }
    } catch (e) {
      console.warn("AUDIO PLAYER: Error aborting previous load:", e);
    }
    
    // Create new abort controller for this load
    const controller = new AbortController();
    window.__prevAbortController = controller;
    wsInstance._abortController = controller;
    
    // Create a safety timeout
    const loadTimeout = setTimeout(() => {
      if (mounted.current && controller && !controller.signal.aborted) {
        console.warn("AUDIO PLAYER: Load timed out after 30 seconds, continuing");
        controller.abort();
        
        // Fallback to simple audio element
        if (audioElementRef.current) {
          try {
            audioElementRef.current.src = url;
            audioElementRef.current.load();
            setAudioLoaded(true);
            setIsLoadingAudio(false);
          } catch (loadErr) {
            console.warn("AUDIO PLAYER: Error loading audio in fallback element:", loadErr);
          }
        }
      }
    }, 30000);
    
    // Store timeout for cleanup
    safetyTimeoutsRef.current.push(loadTimeout);
    
    // Attempt to pause before loading (with error handling)
    try {
      if (typeof wsInstance.pause === 'function') {
        wsInstance.pause();
      }
    } catch (pauseErr) {
      console.warn("AUDIO PLAYER: Error pausing before load:", pauseErr);
    }
    
    // Now try to load the audio
    try {
      wsInstance.load(url);
    } catch (err) {
      console.error("AUDIO PLAYER: Error during load:", err);
      
      // Fallback to audio element
      if (audioElementRef.current) {
        try {
          audioElementRef.current.src = url;
          audioElementRef.current.load();
          setAudioLoaded(true);
          setIsLoadingAudio(false);
        } catch (loadErr) {
          console.warn("AUDIO PLAYER: Error loading audio in fallback element:", loadErr);
        }
      }
      
      // Clean up timeout
      clearTimeout(loadTimeout);
      safetyTimeoutsRef.current = safetyTimeoutsRef.current.filter(id => id !== loadTimeout);
    }
  }, [isValidUrl, safeInitializeWaveSurfer, audioElementRef, mounted, setAudioLoaded, setIsLoadingAudio]);

  // Initialize WaveSurfer
  const initializeWaveSurfer = useCallback(() => {
    // Check if WaveSurfer is already initialized and valid
    if (waveSurferRef.current && typeof waveSurferRef.current.destroy === 'function') {
      try {
        // Make sure the instance is still valid
        if (typeof waveSurferRef.current.getVolume === 'function') {
          return waveSurferRef.current;
        } else {
          // Instance is corrupted, need to destroy it first
          console.log("WaveSurfer instance corrupted, recreating");
          try {
            waveSurferRef.current.destroy();
          } catch (err) {
            console.log("Failed to destroy corrupted WaveSurfer instance:", err);
          }
          waveSurferRef.current = null;
        }
      } catch (err) {
        console.log("Error checking WaveSurfer instance:", err);
        waveSurferRef.current = null;
      }
    }
    
    if (!WaveSurfer) {
      console.error("WaveSurfer library is not available");
      setShowWaveSurferError(true);
      return null;
    }
    
    try {
      const container = isFullscreenMobile ? mobileWaveformContainerRef.current : waveformContainerRef.current;
      if (!container) {
        console.error("Container ref not available");
        return null;
      }
      
      // Ensure container is visible with proper dimensions
      container.style.setProperty('display', 'block', 'important');
      container.style.setProperty('visibility', 'visible', 'important');
      container.style.setProperty('width', '100%', 'important');
      container.style.setProperty('min-width', '300px', 'important');
      container.style.setProperty('height', isMobile ? '60px' : '80px', 'important');
      container.style.setProperty('min-height', isMobile ? '60px' : '80px', 'important');
      
      // Force a reflow
      void container.offsetHeight;
      void container.offsetWidth;
      
      const rect = container.getBoundingClientRect();
      console.log("Container dimensions:", rect.width, "x", rect.height);
      
      // Only check for truly invalid dimensions
      if (rect.width <= 0 || rect.height <= 0) {
        console.warn("Container has zero dimensions, cannot initialize WaveSurfer");
        if (audioUrl && isValidUrl(audioUrl)) {
          createFallbackAudioElement(audioUrl);
          setAudioLoaded(true);
          setIsLoadingAudio(false);
        }
        return null;
      }
      
      if (!container.id) {
        container.id = `waveform-container-${Date.now()}`;
      }
      
      // Check for existing instance in global registry
      if (window._activeSurfers && window._activeSurfers[container.id]) {
        // Verify the instance is still valid
        try {
          const existingInstance = window._activeSurfers[container.id];
          if (typeof existingInstance.getVolume === 'function') {
            console.log("Found existing WaveSurfer instance for container, reusing");
            return existingInstance;
          } else {
            console.log("Existing instance is corrupted, will create new one");
            try {
              existingInstance.destroy();
            } catch (err) {
              console.warn("Error destroying corrupted instance:", err);
            }
            delete window._activeSurfers[container.id];
          }
        } catch (err) {
          console.warn("Error checking existing WaveSurfer instance:", err);
          delete window._activeSurfers[container.id];
        }
      }
      
      // Setup plugins
      const plugins = [];
      try {
        if (!isFullscreenMobile && timelineContainerRef.current && TimelinePlugin) {
          const timelineContainer = timelineContainerRef.current;
          timelineContainer.style.setProperty('min-width', '100px', 'important');
          timelineContainer.style.setProperty('min-height', '30px', 'important');
          timelineContainer.style.setProperty('width', '100%', 'important');
          timelineContainer.style.setProperty('display', 'block', 'important');
          const timelinePlugin = TimelinePlugin.create({
            container: timelineContainer,
            primaryLabelInterval: 10,
            secondaryLabelInterval: 5,
            primaryColor: 'rgba(255,255,255,0.8)',
            secondaryColor: 'rgba(255,255,255,0.4)',
            primaryFontColor: 'rgba(255,255,255,0.8)',
            secondaryFontColor: 'rgba(255,255,255,0.4)',
            fontFamily: 'Arial',
            fontSize: 12
          });
          plugins.push(timelinePlugin);
        }
      } catch (timelineErr) {
        console.warn("Error creating timeline plugin:", timelineErr);
      }
      
      try {
        if (RegionsPlugin) {
          const regionsPlugin = RegionsPlugin.create({});
          plugins.push(regionsPlugin);
        }
      } catch (regionsErr) {
        console.warn("Error creating regions plugin:", regionsErr);
      }
      
      // Ensure we have an audio element before proceeding
      if (!audioElementRef.current) {
        console.log("Creating audio element before WaveSurfer initialization");
        const audioEl = new Audio();
        audioEl.crossOrigin = "anonymous";
        audioEl.preload = "auto";
        if (audioUrl && isValidUrl(audioUrl)) {
          audioEl.src = audioUrl;
        }
        audioElementRef.current = audioEl;
        if (globalAudioRef.current) {
          globalAudioRef.current._audioElement = audioEl;
        }
        setupAudioElementListeners(audioEl);
      }
      
      const sharedAudioElement = audioElementRef.current;
      
      // Create WaveSurfer instance with proper configuration
      const wsOptions = {
        container: container,
        waveColor: 'rgba(255, 255, 255, 0.6)', // White for non-played sections
        progressColor: '#7d12ff', // Purple for played sections
        cursorColor: '#ffffff',
        barWidth: 2,
        barRadius: 3,
        cursorWidth: 1,
        height: isMobile ? 60 : 80,
        barGap: 2,
        responsive: true,
        normalize: true,
        pixelRatio: 1,
        scrollParent: true,
        minPxPerSec: 100, // Fixed high zoom level
        hideScrollbar: false,
        forceDecode: true,
        plugins: plugins
      };
      
      // Use media element backend if we have an audio element
      if (sharedAudioElement) {
        wsOptions.backend = 'MediaElement';
        wsOptions.media = sharedAudioElement;
      }
      
      // Create WaveSurfer instance
      const wsInstance = WaveSurfer.create(wsOptions);
      
      // Register the instance globally
      container._wavesurfer = wsInstance;
      if (!window._activeSurfers) window._activeSurfers = {};
      window._activeSurfers[container.id] = wsInstance;
      
      // Set up events with proper error handling
      wsInstance.on('ready', () => {
        if (!isMountedRef.current) return;
        
        console.log("WaveSurfer ready event fired");
        setAudioLoaded(true);
        setIsLoadingAudio(false);
        
        try {
          const dur = wsInstance.getDuration();
          if (dur && !isNaN(dur) && isFinite(dur)) {
            setDuration(dur);
            if (onAudioLoaded) {
              onAudioLoaded({ url: audioUrl, duration: dur, wavesurferReady: true });
            }
          }
          
          // Enable auto-scrolling
          if (wsInstance.params) {
            wsInstance.params.scrollParent = true;
          }
        } catch (err) {
          console.warn("Error getting duration:", err);
        }
        
        // Initialize audio analysis if needed
        if (!analyzerRef.current && initializeAudioAnalysisRef.current) {
          // Delay analysis initialization to ensure audio element is ready
          setTimeout(() => {
            if (isMountedRef.current) {
              initializeAudioAnalysisRef.current();
            }
          }, 100);
        }
      });
      
      // Add audioprocess handler to ensure waveform scrolls during playback
      wsInstance.on('audioprocess', () => {
        if (!isMountedRef.current) return;
        
        try {
          // Ensure the cursor remains visible by scrolling the container
          if (wsInstance.drawer && typeof wsInstance.drawer.recenter === 'function') {
            const currentProgress = wsInstance.getCurrentTime() / (wsInstance.getDuration() || 1);
            wsInstance.drawer.recenter(currentProgress);
          }
        } catch (err) {
          console.warn("Error during audioprocess event:", err);
        }
      });
      
      wsInstance.on('error', (err) => {
        console.error("WaveSurfer error:", err);
        if (!isMountedRef.current) return;
        
        const isAbortError = err && (
          err.name === 'AbortError' ||
          err.toString().includes('abort') ||
          err.message?.includes('abort')
        );
        
        if (isAbortError) {
          console.log("AbortError detected - normal during cleanup");
          return;
        }
        
        setIsLoadingAudio(false);
        setShowWaveSurferError(true);
        safeToast('error', 'Using simplified audio player for better compatibility');
        
        if (audioUrl && isValidUrl(audioUrl)) {
          createFallbackAudioElement(audioUrl);
          setAudioLoaded(true);
        }
      });
      
      wsInstance.on('play', () => {
        if (!isMountedRef.current) return;
        
        console.log("AUDIO PLAYER: WaveSurfer play event");
        setIsPlaying(true);
        lastPlayingStateRef.current = true;
        
        if (audioElementRef.current && audioElementRef.current.paused) {
          try {
            audioElementRef.current.play().catch(err => {
              console.warn("Error starting audio element playback:", err);
            });
          } catch (err) {
            console.warn("Error starting audio element playback:", err);
          }
        }
        
        if (audioContextRef.current && audioContextRef.current.state === 'suspended') {
          try {
            audioContextRef.current.resume().catch(err => {
              console.warn("Error resuming AudioContext:", err);
            });
          } catch (err) {
            console.warn("Error resuming AudioContext:", err);
          }
        }
        
        if (globalAudioRef.current) {
          globalAudioRef.current.state.audioPlaying = true;
          try { sessionStorage.setItem('audioPlaying', 'true'); } catch (e) {}
        }
      });
      
      wsInstance.on('pause', () => {
        if (!isMountedRef.current) return;
        
        console.log("AUDIO PLAYER: WaveSurfer pause event");
        setIsPlaying(false);
        lastPlayingStateRef.current = false;
        
        if (audioElementRef.current && !audioElementRef.current.paused) {
          try { 
            audioElementRef.current.pause(); 
          } catch (err) { 
            console.warn("Error pausing audio element:", err); 
          }
        }
        
        if (globalAudioRef.current) {
          globalAudioRef.current.state.audioPlaying = false;
          try { sessionStorage.setItem('audioPlaying', 'false'); } catch (e) {}
        }
      });
      
      wsInstance.on('seeking', (progress) => {
        if (!isMountedRef.current) return;
        
        try {
          const newTime = progress * (wsInstance.getDuration() || 0);
          setCurrentTime(newTime);
          
          if (audioElementRef.current) {
            try { 
              audioElementRef.current.currentTime = newTime; 
            } catch (err) { 
              console.warn("Error syncing audio element time:", err); 
            }
          }
        } catch (err) {
          console.warn("Error during seeking event:", err);
        }
      });
      
      return wsInstance;
    } catch (err) {
      console.error("Error initializing WaveSurfer:", err);
      
      if (isMountedRef.current) {
        setShowWaveSurferError(true);
        safeToast('error', 'Using simplified audio player for better compatibility');
        
        if (audioUrl && isValidUrl(audioUrl)) {
          createFallbackAudioElement(audioUrl);
          setAudioLoaded(true);
          setIsLoadingAudio(false);
        }
      }
      
      return null;
    }
  }, [isMobile, isFullscreenMobile, audioUrl, isValidUrl, createFallbackAudioElement, setupAudioElementListeners, safeToast, isMountedRef, onAudioLoaded]);

  

  useEffect(() => {
    // Set up the analysis and WaveSurfer initialization functions
    initializeWaveSurferRef.current = initializeWaveSurfer;
    
    // Assign a wrapper for the imported initializeAudioAnalysis
    initializeAudioAnalysisRef.current = () => {
      // Make sure the audio context is not closed before initializing
      let context = audioContextRef.current;
      
      // If context is closed, create a new one before calling the imported function
      if (context && context.state === 'closed') {
        try {
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          if (AudioContext) {
            audioContextRef.current = new AudioContext();
          }
        } catch (err) {
          console.warn("Error creating new AudioContext:", err);
        }
      }
      
      // Call the imported initializeAudioAnalysis with proper parameters
      const analyzerNode = initializeAudioAnalysis({
        audioElement: audioElementRef.current,
        audioContext: audioContextRef.current,
        audioContextRef,
        analyzerRef,
        mounted: isMountedRef,
        isUsingGlobalAudio,
        globalAudioRef,
        setAnalyzerNode
      });
      
      return analyzerNode;
    };
    
  }, [initializeWaveSurfer, initializeAudioAnalysis, audioElementRef, audioContextRef, analyzerRef, isUsingGlobalAudio, globalAudioRef, setAnalyzerNode, isMountedRef]);

  const setupGlobalAudioIntegration = useCallback(() => {
    if (!globalAudioRef || !globalAudioRef.current) {
      console.log("AUDIO PLAYER: No global audio reference available");
      return false;
    }
    
    console.log("AUDIO PLAYER: Setting up global audio integration");
    
    // Set handler for global audio URL updates
    const unsubscribeUrl = globalAudioRef.current.subscribe('audio-url-updated', (detail) => {
      if (!mounted.current) return;
      
      const newUrl = detail.url;
      if (newUrl && isValidUrl(newUrl) && newUrl !== audioUrl) {
        console.log("AUDIO PLAYER: Received global audio URL update:", newUrl);
        
        setAudioUrl(newUrl);
        audioUrlRef.current = newUrl;
        
        // Update WaveSurfer if available
        if (waveSurferRef.current && typeof waveSurferRef.current.load === 'function') {
          safeLoadAudio(newUrl, waveSurferRef.current);
        } else if (audioElementRef.current) {
          try {
            audioElementRef.current.src = newUrl;
            audioElementRef.current.load();
          } catch (err) {
            console.warn("AUDIO PLAYER: Error updating audio element URL:", err);
          }
        }
      }
    });
    
    // Set handler for playback state updates
    const unsubscribePlay = globalAudioRef.current.subscribe('playback-state-changed', (detail) => {
      if (!mounted.current) return;
      
      if (detail.isPlaying !== undefined && detail.isPlaying !== isPlaying) {
        setIsPlaying(detail.isPlaying);
        
        if (waveSurferRef.current) {
          try {
            if (detail.isPlaying) {
              waveSurferRef.current.play();
            } else {
              waveSurferRef.current.pause();
            }
          } catch (err) {
            console.warn("AUDIO PLAYER: Error syncing playback state:", err);
          }
        }
      }
    });
    
    // Share our audio element with global state if needed
    if (!globalAudioRef.current._audioElement && audioElementRef.current) {
      globalAudioRef.current._audioElement = audioElementRef.current;
      setIsUsingGlobalAudio(true);
    } else if (globalAudioRef.current._audioElement && !audioElementRef.current) {
      audioElementRef.current = globalAudioRef.current._audioElement;
      setIsUsingGlobalAudio(true);
    }
    
    // Ensure audio context is shared
    if (audioContextRef.current && !globalAudioRef.current._audioContext) {
      globalAudioRef.current._audioContext = audioContextRef.current;
    } else if (!audioContextRef.current && globalAudioRef.current._audioContext) {
      audioContextRef.current = globalAudioRef.current._audioContext;
    }
    
    // Ensure analyzer is shared
    if (analyzerRef.current && !globalAudioRef.current._analyzer) {
      globalAudioRef.current._analyzer = analyzerRef.current;
    } else if (!analyzerRef.current && globalAudioRef.current._analyzer) {
      analyzerRef.current = globalAudioRef.current._analyzer;
      setAnalyzerNode(globalAudioRef.current._analyzer);
    }
    
    // Store cleanup functions for later
    const cleanupFunctions = [unsubscribeUrl, unsubscribePlay];
    
    return () => {
      cleanupFunctions.forEach(fn => {
        if (typeof fn === 'function') {
          fn();
        }
      });
    };
  }, [globalAudioRef, mounted, isValidUrl, audioUrl, setAudioUrl, waveSurferRef, audioElementRef, safeLoadAudio, isPlaying, setIsPlaying, audioContextRef, analyzerRef, setAnalyzerNode, setIsUsingGlobalAudio]);
  
  // Use this in your useEffect for integration
  useEffect(() => {
    const cleanup = setupGlobalAudioIntegration();
    
    return () => {
      if (typeof cleanup === 'function') {
        cleanup();
      }
    };
  }, [setupGlobalAudioIntegration]);

  const safeInitializeWaveSurfer = useCallback(() => {
    if (waveSurferRef.current) {
      try {
        // Safety check - verify the instance is still valid before reusing
        if (typeof waveSurferRef.current.getVolume === 'function') {
          console.log("AUDIO PLAYER: Reusing existing WaveSurfer instance");
          return waveSurferRef.current;
        } else {
          // Instance is corrupted, need to destroy it first
          console.log("AUDIO PLAYER: WaveSurfer instance corrupted, recreating");
          try {
            waveSurferRef.current.destroy();
          } catch (err) {
            console.warn("AUDIO PLAYER: Failed to destroy corrupted WaveSurfer instance:", err);
          }
          waveSurferRef.current = null;
        }
      } catch (err) {
        console.warn("AUDIO PLAYER: Error checking WaveSurfer instance:", err);
        waveSurferRef.current = null;
      }
    }
    
    if (!WaveSurfer) {
      console.error("AUDIO PLAYER: WaveSurfer library not available");
      setShowWaveSurferError(true);
      return null;
    }
    
    const container = isFullscreenMobile ? mobileWaveformContainerRef.current : waveformContainerRef.current;
    if (!container) {
      console.error("AUDIO PLAYER: Container ref not available");
      return null;
    }
    
    // Ensure container is visible with dimensions
    const rect = container.getBoundingClientRect();
    if (rect.width <= 0 || rect.height <= 0) {
      console.warn("AUDIO PLAYER: Container has zero dimensions, cannot initialize WaveSurfer");
      return null;
    }
    
    try {
      // Set up plugins
      const plugins = [];
      if (!isFullscreenMobile && timelineContainerRef.current && TimelinePlugin) {
        try {
          const timelinePlugin = TimelinePlugin.create({
            container: timelineContainerRef.current,
            primaryLabelInterval: 10,
            secondaryLabelInterval: 5,
            primaryColor: 'rgba(255,255,255,0.8)',
            secondaryColor: 'rgba(255,255,255,0.4)',
            primaryFontColor: 'rgba(255,255,255,0.8)',
            secondaryFontColor: 'rgba(255,255,255,0.4)',
            fontFamily: 'Arial',
            fontSize: 12
          });
          plugins.push(timelinePlugin);
        } catch (err) {
          console.warn("AUDIO PLAYER: Error creating timeline plugin:", err);
        }
      }
      
      if (RegionsPlugin) {
        try {
          const regionsPlugin = RegionsPlugin.create();
          plugins.push(regionsPlugin);
        } catch (err) {
          console.warn("AUDIO PLAYER: Error creating regions plugin:", err);
        }
      }
      
      // CRITICAL: Use globalAudioState integration if available
      const globalAudioElement = globalAudioRef.current && 
                                globalAudioRef.current._audioElement;
                                
      // Create WaveSurfer with proper configuration
      const wsOptions = {
        container: container,
        waveColor: 'rgba(255, 255, 255, 0.6)',
        progressColor: '#7d12ff',
        cursorColor: '#ffffff',
        barWidth: 2,
        barRadius: 3,
        cursorWidth: 1,
        height: isMobile ? 60 : 80,
        barGap: 2,
        responsive: true,
        normalize: true,
        pixelRatio: 1,
        scrollParent: true,
        minPxPerSec: 100,
        hideScrollbar: false,
        forceDecode: true,
        plugins: plugins
      };
      
      // Use media element backend if we have an audio element
      if (globalAudioElement) {
        console.log("AUDIO PLAYER: Using global audio element for WaveSurfer");
        wsOptions.backend = 'MediaElement';
        wsOptions.media = globalAudioElement;
        audioElementRef.current = globalAudioElement;
      } else if (audioElementRef.current) {
        console.log("AUDIO PLAYER: Using component audio element for WaveSurfer");
        wsOptions.backend = 'MediaElement';
        wsOptions.media = audioElementRef.current;
      }
      
      // Create the instance
      const wsInstance = WaveSurfer.create(wsOptions);
      
      // Set up events with defensive error handling
      wsInstance.on('ready', () => {
        if (!mounted.current) return;
        
        console.log("AUDIO PLAYER: WaveSurfer ready event fired");
        setAudioLoaded(true);
        setIsLoadingAudio(false);
        
        try {
          const dur = wsInstance.getDuration();
          if (dur && !isNaN(dur) && isFinite(dur)) {
            setDuration(dur);
            if (onAudioLoaded) {
              onAudioLoaded({ url: audioUrl, duration: dur, wavesurferReady: true });
            }
          }
        } catch (err) {
          console.warn("AUDIO PLAYER: Error getting duration:", err);
        }
        
        // Initialize audio analysis if needed
        if (!analyzerRef.current && initializeAudioAnalysisRef.current) {
          setTimeout(() => {
            if (mounted.current) {
              initializeAudioAnalysisRef.current();
            }
          }, 100);
        }
      });
      
      // Handle errors properly
      wsInstance.on('error', (err) => {
        if (!mounted.current) return;
        
        // Don't treat aborts as errors
        const isAbortError = err && (
          err.name === 'AbortError' ||
          err.toString().includes('abort') ||
          err.message?.includes('abort')
        );
        
        if (isAbortError) {
          console.log("AUDIO PLAYER: AbortError detected - normal during cleanup");
          return;
        }
        
        console.error("AUDIO PLAYER: WaveSurfer error:", err);
        setIsLoadingAudio(false);
        setShowWaveSurferError(true);
        
        // Fallback to simple audio element playback
        if (audioUrl && isValidUrl(audioUrl)) {
          createFallbackAudioElement(audioUrl);
          setAudioLoaded(true);
        }
      });
      
      // Set up event handlers
      wsInstance.on('play', () => {
        if (!mounted.current) return;
        setIsPlaying(true);
        lastPlayingStateRef.current = true;
        
        if (globalAudioRef.current) {
          globalAudioRef.current.state.audioPlaying = true;
        }
      });
      
      wsInstance.on('pause', () => {
        if (!mounted.current) return;
        setIsPlaying(false);
        lastPlayingStateRef.current = false;
        
        if (globalAudioRef.current) {
          globalAudioRef.current.state.audioPlaying = false;
        }
      });
      
      // Add custom cleanup method to the instance
      wsInstance.safeDestroy = function() {
        try {
          if (typeof this.pause === 'function') {
            this.pause();
          }
          
          if (typeof this.destroy === 'function') {
            this.destroy();
          }
          return true;
        } catch (err) {
          console.warn("AUDIO PLAYER: Error in safeDestroy:", err);
          return false;
        }
      };
      
      // Set up seeking handler
      wsInstance.on('seeking', (progress) => {
        if (!mounted.current) return;
        
        try {
          const newTime = progress * (wsInstance.getDuration() || 0);
          setCurrentTime(newTime);
          
          if (audioElementRef.current) {
            audioElementRef.current.currentTime = newTime;
          }
        } catch (err) {
          console.warn("AUDIO PLAYER: Error during seeking:", err);
        }
      });
      
      return wsInstance;
    } catch (err) {
      console.error("AUDIO PLAYER: Error creating WaveSurfer instance:", err);
      
      if (mounted.current) {
        setShowWaveSurferError(true);
        
        // Fallback to simple audio element
        if (audioUrl && isValidUrl(audioUrl)) {
          createFallbackAudioElement(audioUrl);
          setAudioLoaded(true);
          setIsLoadingAudio(false);
        }
      }
      
      return null;
    }
  }, [isMobile, isFullscreenMobile, createFallbackAudioElement, isValidUrl, audioUrl, onAudioLoaded, mounted, safeToast, globalAudioRef]);

  useEffect(() => {
    safeInitializeWaveSurferRef.current = safeInitializeWaveSurfer;
  }, [safeInitializeWaveSurfer]);

  const handleScrubberClick = useCallback((e) => {
    if (!waveSurferRef.current || !audioLoaded) return;
    
    try {
      const container = e.currentTarget;
      const rect = container.getBoundingClientRect();
      const relativeX = e.clientX - rect.left;
      const percentage = relativeX / rect.width;
      
      if (isNaN(percentage) || percentage < 0 || percentage > 1) return;
      
      const duration = waveSurferRef.current.getDuration() || 0;
      if (duration <= 0) return;
      
      waveSurferRef.current.seekTo(percentage);
      setCurrentTime(percentage * duration);
      
      // Also update audio element for better synchronization
      if (audioElementRef.current) {
        try {
          audioElementRef.current.currentTime = percentage * duration;
        } catch (err) {
          console.warn("Error updating audio element time:", err);
        }
      }
      
      // Update global state if available
      if (globalAudioRef.current) {
        globalAudioRef.current.publishEvent('seek', { 
          percentage, 
          time: percentage * duration,
          source: 'waveform-click' 
        });
      }
    } catch (error) {
      console.error("AUDIO PLAYER: Error in scrubber click handler:", error);
    }
  }, [waveSurferRef, audioLoaded, audioElementRef, globalAudioRef]);



  // Define missing handlers
// Replace the togglePlayback method with this more robust version
const togglePlayback = useCallback(() => {
  // First, attempt to use the imported syncPlayback if available
  if (typeof syncPlayback === 'function') {
    const success = syncPlayback({
      isPlaying: !isPlaying,
      audioElement: audioElementRef.current,
      waveSurfer: waveSurferRef.current,
      globalAudioState: globalAudioRef.current
    });
    
    if (success) return;
    
    // If syncPlayback fails, continue with fallback approach
    console.log("AUDIO PLAYER: syncPlayback failed, using fallback approach");
  }
  
  // Try WaveSurfer first
  if (waveSurferRef.current) {
    try {
      if (typeof waveSurferRef.current.isPlaying === 'function' && 
          typeof waveSurferRef.current.play === 'function' && 
          typeof waveSurferRef.current.pause === 'function') {
        
        const currentlyPlaying = waveSurferRef.current.isPlaying();
        if (currentlyPlaying) {
          waveSurferRef.current.pause();
        } else {
          waveSurferRef.current.play();
        }
        
        // Updated state will be set via WaveSurfer events
        return;
      }
    } catch (err) {
      console.warn("AUDIO PLAYER: Error toggling WaveSurfer playback:", err);
      // Continue to try audio element approach
    }
  }
  
  // Fallback to audio element
  if (audioElementRef.current) {
    try {
      if (audioElementRef.current.paused) {
        const playPromise = audioElementRef.current.play();
        if (playPromise !== undefined) {
          playPromise.then(() => {
            setIsPlaying(true);
            if (globalAudioRef.current) {
              globalAudioRef.current.state.audioPlaying = true;
              try { sessionStorage.setItem('audioPlaying', 'true'); } catch (e) {}
            }
          }).catch(err => {
            console.warn("AUDIO PLAYER: Error playing audio:", err);
            
            // Check for autoplay restriction
            if (err.name === 'NotAllowedError') {
              safeToast('info', 'Audio playback requires user interaction first.');
              
              // Set up auto-play on next user interaction
              const handleUserInteraction = () => {
                audioElementRef.current.play().catch(e => {
                  console.warn("AUDIO PLAYER: Second play attempt failed:", e);
                });
                
                // Clean up event listeners
                document.removeEventListener('click', handleUserInteraction);
                document.removeEventListener('touchstart', handleUserInteraction);
                document.removeEventListener('keydown', handleUserInteraction);
              };
              
              document.addEventListener('click', handleUserInteraction, { once: true });
              document.addEventListener('touchstart', handleUserInteraction, { once: true });
              document.addEventListener('keydown', handleUserInteraction, { once: true });
            }
          });
        }
      } else {
        audioElementRef.current.pause();
        setIsPlaying(false);
        if (globalAudioRef.current) {
          globalAudioRef.current.state.audioPlaying = false;
          try { sessionStorage.setItem('audioPlaying', 'false'); } catch (e) {}
        }
      }
      
      return;
    } catch (err) {
      console.error("AUDIO PLAYER: Error controlling audio element:", err);
    }
  }
  
  // If we get here, neither method worked
  safeToast('error', 'Audio player not responding. Please try again.');
  
  // Toggle state manually as fallback
  setIsPlaying(!isPlaying);
}, [isPlaying, audioElementRef, waveSurferRef, globalAudioRef, syncPlayback, safeToast, setIsPlaying]);

const handleSkip = useCallback((seconds) => {
  try {
    // Try with WaveSurfer first
    if (waveSurferRef.current) {
      const currentTime = waveSurferRef.current.getCurrentTime() || 0;
      const newTime = Math.max(0, currentTime + seconds);
      const duration = waveSurferRef.current.getDuration() || 0;
      
      if (newTime > duration) {
        // Skip to end
        waveSurferRef.current.seekTo(1);
      } else {
        // Skip forward/backward
        waveSurferRef.current.seekTo(newTime / duration);
      }
      
      // Also update audio element for better sync
      if (audioElementRef.current) {
        audioElementRef.current.currentTime = newTime;
      }
      
      // Update the current time display
      setCurrentTime(newTime);
      
      return;
    }
    
    // Fallback to audio element if WaveSurfer isn't available
    if (audioElementRef.current) {
      const currentTime = audioElementRef.current.currentTime || 0;
      const newTime = Math.max(0, currentTime + seconds);
      
      if (newTime > (audioElementRef.current.duration || 0)) {
        audioElementRef.current.currentTime = audioElementRef.current.duration || 0;
      } else {
        audioElementRef.current.currentTime = newTime;
      }
      
      setCurrentTime(newTime);
    }
  } catch (error) {
    console.error("Error during skip:", error);
  }
}, [waveSurferRef, audioElementRef]);



  const handleVolumeChange = useCallback((newVolume) => {
    setVolume(newVolume);
    if (waveSurferRef.current) {
      try {
        waveSurferRef.current.setVolume(newVolume);
      } catch (error) {
        console.error("AUDIO PLAYER: Error changing WaveSurfer volume:", error);
      }
    }
    if (audioElementRef.current) {
      try {
        audioElementRef.current.volume = newVolume;
      } catch (error) {
        console.error("AUDIO PLAYER: Error changing audio element volume:", error);
      }
    }
  }, []);

  const toggleMute = useCallback(() => {
    const newMuteState = !isMuted;
    setIsMuted(newMuteState);
    if (waveSurferRef.current) {
      try {
        waveSurferRef.current.setMute(newMuteState);
      } catch (error) {
        console.error("AUDIO PLAYER: Error toggling WaveSurfer mute:", error);
      }
    }
    if (audioElementRef.current) {
      try {
        audioElementRef.current.muted = newMuteState;
      } catch (error) {
        console.error("AUDIO PLAYER: Error toggling audio element mute:", error);
      }
    }
  }, [isMuted]);

  const toggleFullscreen = useCallback(() => {
    setIsFullscreen(prev => !prev);
  }, []);

  const toggleVisualizer = useCallback(() => {
    setUseVisualizer(prev => !prev);
  }, []);

  const togglePlaylist = useCallback(() => {
    setShowPlaylist(prev => !prev);
  }, []);

  const handleMusicSelect = useCallback((trackName) => {
    setSelectedMusic(trackName);
    setShowPlaylist(false);
    if (globalAudioRef.current) {
      globalAudioRef.current.publishEvent('music-selected', { trackName, timestamp: Date.now() });
    }
    if (typeof onApplyChanges === 'function') {
      setIsLoadingAudio(true);
      onApplyChanges().catch(err => {
        console.error("AUDIO PLAYER: Error in onApplyChanges:", err);
        safeToast('error', 'Failed to change music track');
        setIsLoadingAudio(false);
      });
    }
  }, [setSelectedMusic, onApplyChanges, safeToast]);

  const formatTime = useCallback((timeInSeconds) => {
    if (isNaN(timeInSeconds) || timeInSeconds < 0) return '0:00';
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }, []);

  const getTrackInfo = useCallback(() => {
    try {
      if (!selectedMusic || !Array.isArray(musicLibrary) || musicLibrary.length === 0) {
        return { title: 'Your Meditation', artist: 'Pause' };
      }
      const track = musicLibrary.find(t => t && typeof t === 'object' && t.name === selectedMusic);
      if (track) {
        return {
          title: track.displayName || track.name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
          artist: 'Pause Music'
        };
      } else {
        return {
          title: selectedMusic ? selectedMusic.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) : 'Your Meditation',
          artist: 'Pause'
        };
      }
    } catch (err) {
      console.error("AUDIO PLAYER: Error in getTrackInfo:", err);
      return { title: 'Meditation', artist: 'Pause' };
    }
  }, [selectedMusic, musicLibrary]);

  const getAlbumArt = useCallback((trackName = selectedMusic) => {
    const coverArtOptions = [
      'https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1447752875215-b2761acb3c5d?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1433086966358-54859d0ed716?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1501854140801-50d01698950b?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1528722828814-77b9b83aafb2?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1475274047050-1d0c0975c63e?w=600&h=600&fit=crop&auto=format'
    ];
    if (!trackName) return coverArtOptions[0];
    try {
      const cleanName = trackName.replace(/^Background_Music\/?/i, '').replace(/\.mp3$/i, '').trim();
      let hash = 0;
      for (let i = 0; i < cleanName.length; i++) {
        hash = (hash << 5) - hash + cleanName.charCodeAt(i);
        hash |= 0;
      }
      const index = Math.abs(hash) % coverArtOptions.length;
      return coverArtOptions[index];
    } catch (error) {
      console.error('Error getting cover art:', error);
      return coverArtOptions[0];
    }
  }, [selectedMusic]);

  const getMoodLabel = useCallback((trackName = selectedMusic) => {
    if (!trackName) return 'Meditation Music';
    try {
      const cleanTrackName = trackName.replace(/^Background_Music\/?/i, '').replace(/\.mp3$/i, '').trim();
      const lowerName = cleanTrackName.toLowerCase();
      const moodMap = [
        { keywords: ['calm', 'peaceful', 'gentle'], label: 'Calm & Peaceful' },
        { keywords: ['ocean', 'sea', 'water', 'wave'], label: 'Ocean Soundscape' },
        { keywords: ['rain', 'storm', 'thunder'], label: 'Rainfall Ambience' },
        { keywords: ['forest', 'nature', 'bird'], label: 'Forest Sounds' },
        { keywords: ['night', 'evening', 'twilight'], label: 'Night Ambience' },
        { keywords: ['space', 'cosmic', 'universe'], label: 'Cosmic Journey' },
        { keywords: ['meditat', 'zen', 'mindful'], label: 'Deep Meditation' },
        { keywords: ['dream', 'sleep', 'relax'], label: 'Sleep & Relaxation' },
        { keywords: ['piano', 'string', 'classic'], label: 'Gentle Classical' },
        { keywords: ['ambient', 'atmosphere'], label: 'Ambient Atmosphere' },
        { keywords: ['journey', 'adventure'], label: 'Inner Journey' }
      ];
      for (const mood of moodMap) {
        if (mood.keywords.some(keyword => lowerName.includes(keyword))) {
          return mood.label;
        }
      }
      return cleanTrackName.length > 15 ? 'Meditation Music' : 'Relaxing Ambience';
    } catch (error) {
      console.error('Error getting mood label:', error);
      return 'Meditation Music';
    }
  }, [selectedMusic]);

  const renderVisualizer = useCallback(() => {
    if (!useVisualizer) {
      return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#02073c', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '60%', height: '60%', borderRadius: '50%', background: 'radial-gradient(circle, #7d12ff 0%, #5636f3 70%, #02073c 100%)', opacity: isPlaying ? 0.8 : 0.5, transition: 'all 0.3s ease', animation: isPlaying ? 'pulse 3s infinite alternate' : 'none' }}></div>
          <style jsx>{`
            @keyframes pulse {
              0% { transform: scale(0.95); opacity: 0.7; }
              100% { transform: scale(1.05); opacity: 0.9; }
            }
          `}</style>
        </div>
      );
    }
    try {
      const currentAnalyzer = analyzerNode || analyzerRef.current;
      if (typeof VisualizerLoader === 'function') {
        return (
          <VisualizerLoader
            analyzerNode={currentAnalyzer}
            isPlaying={isPlaying}
            size={visualizerSize}
            audioUrl={audioUrl}
            fallbackOnly={!currentAnalyzer}
          />
        );
      }
      return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#02073c', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '70%', height: '70%', borderRadius: '50%', background: 'radial-gradient(circle, #7d12ff 0%, #5636f3 70%, #02073c 100%)', opacity: isPlaying ? 0.8 : 0.5, transition: 'all 0.3s ease', animation: isPlaying ? 'pulse 3s infinite alternate' : 'none' }}></div>
          <style jsx>{`
            @keyframes pulse {
              0% { transform: scale(0.95); opacity: 0.7; }
              100% { transform: scale(1.05); opacity: 0.9; }
            }
          `}</style>
        </div>
      );
    } catch (error) {
      console.error("Error rendering visualizer:", error);
      return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#02073c', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '60%', height: '60%', borderRadius: '50%', background: 'radial-gradient(circle, #7d12ff 0%, #5636f3 70%, #02073c 100%)', opacity: 0.6, transition: 'all 0.3s ease' }}></div>
        </div>
      );
    }
  }, [analyzerNode, analyzerRef, isPlaying, useVisualizer, visualizerSize, audioUrl]);

  const waveformContainerRefCallback = useCallback((node) => {
    if (!node) {
      console.log("AUDIO PLAYER: Container node is null, skipping initialization");
      return;
    }
    
    console.log("AUDIO PLAYER: Waveform container set:", node, "with dimensions:", node.getBoundingClientRect());
    
    if (isFullscreenMobile) {
      mobileWaveformContainerRef.current = node;
    } else {
      waveformContainerRef.current = node;
    }
    
    if (!node.id) {
      node.id = `waveform-container-${Date.now()}`;
    }
    
    // Apply essential styles
    node.style.setProperty('display', 'block', 'important');
    node.style.setProperty('visibility', 'visible', 'important');
    node.style.setProperty('width', '100%', 'important');
    node.style.setProperty('min-width', '300px', 'important');
    node.style.setProperty('height', isMobile ? '60px' : '80px', 'important');
    node.style.setProperty('min-height', isMobile ? '60px' : '80px', 'important');
    
    // Use a short timeout to ensure styles are applied before measuring
    setTimeout(() => {
      if (!mounted.current) return;
      
      const rect = node.getBoundingClientRect();
      console.log(`AUDIO PLAYER: Container dimensions after timeout: ${rect.width}x${rect.height}`);
      
      // Only check for truly invalid dimensions
      if (rect.width <= 0 || rect.height <= 0) {
        console.warn("AUDIO PLAYER: Container has zero dimensions, using fallback");
        if (isValidUrl(audioUrl)) {
          createFallbackAudioElement(audioUrl);
          setAudioLoaded(true);
          setIsLoadingAudio(false);
        }
        return;
      }
      
      // Initialize WaveSurfer
      if (!waveSurferRef.current && audioUrl && initializeWaveSurferRef.current) {
        console.log("AUDIO PLAYER: Initializing WaveSurfer");
        try {
          const newWaveSurfer = initializeWaveSurferRef.current();
          if (newWaveSurfer) {
            waveSurferRef.current = newWaveSurfer;
            node._wavesurfer = newWaveSurfer;
            safeLoadAudio(audioUrl, newWaveSurfer);
            setWavesurferInitialized(true);
            console.log("AUDIO PLAYER: WaveSurfer successfully initialized");
          } else {
            console.warn("AUDIO PLAYER: WaveSurfer initialization returned null");
            if (isValidUrl(audioUrl)) {
              createFallbackAudioElement(audioUrl);
              setAudioLoaded(true);
              setIsLoadingAudio(false);
            }
          }
        } catch (error) {
          console.error("AUDIO PLAYER: Error during WaveSurfer initialization:", error);
          if (isValidUrl(audioUrl)) {
            createFallbackAudioElement(audioUrl);
            setAudioLoaded(true);
            setIsLoadingAudio(false);
          }
        }
      } else if (waveSurferRef.current) {
        console.log("AUDIO PLAYER: WaveSurfer already exists, reusing");
        node._wavesurfer = waveSurferRef.current;
        setWavesurferInitialized(true);
      }
    }, 50); // Short delay to ensure styles are applied
  }, [isFullscreenMobile, audioUrl, safeLoadAudio, mounted, wavesurferInitialized, isMobile, isValidUrl, createFallbackAudioElement]);

  const handleAudioReady = useCallback((event) => {
    if (!mounted.current) return;
    console.log("AUDIO PLAYER: Audio ready event received");
    setAudioLoaded(true);
    setIsLoadingAudio(false);
    if (event && event.duration) {
      setDuration(event.duration);
    } else if (audioElementRef.current) {
      setDuration(audioElementRef.current.duration || 0);
    }
    if (!analyzerRef.current && initializeAudioAnalysisRef.current) {
      initializeAudioAnalysisRef.current();
    }
    if (onAudioLoaded) {
      onAudioLoaded({ url: audioUrl, duration: event?.duration || audioElementRef.current?.duration || 0 });
    }
  }, [audioUrl, onAudioLoaded]);

  const handleAudioError = useCallback((error) => {
    console.error("AUDIO PLAYER: Error event:", error);
    if (!mounted.current) return;
    safeToast('error', 'Audio playback error. Trying alternative method.');
    if (audioUrl && globalAudioRef.current) {
      const fallbackUrl = globalAudioRef.current.getBestAudioUrl();
      if (fallbackUrl && fallbackUrl !== audioUrl) {
        setAudioUrl(fallbackUrl);
      }
    }
    if (onError) {
      onError(error);
    }
  }, [audioUrl, safeToast, onError]);

  const handleTimeUpdate = useCallback((time) => {
    if (!mounted.current) return;
    setCurrentTime(time);
  }, []);

  const handlePositionChange = useCallback((position) => {
    if (!mounted.current) return;
    setCurrentTime(position);
  }, []);

  const handleExportAudioHandler = useCallback(async () => {
    if (typeof onExportAudio === 'function') {
      try {
        await onExportAudio();
        return;
      } catch (err) {
        console.warn("AUDIO PLAYER: Error with provided export handler:", err);
      }
    }
    let downloadUrl = '';
    if (globalAudioRef.current && typeof globalAudioRef.current.getBestAudioUrl === 'function') {
      downloadUrl = globalAudioRef.current.getBestAudioUrl();
    }
    if (!downloadUrl && audioUrlRef.current) {
      downloadUrl = audioUrlRef.current;
    }
    if (!downloadUrl) {
      downloadUrl = audioUrl;
    }
    const mixedUrl = sessionStorage.getItem('mixedAudioUrl') ||
      window.__mixedAudioUrl ||
      (globalAudioRef.current && globalAudioRef.current.state?.mixedAudioUrl);
    if (mixedUrl) {
      downloadUrl = mixedUrl;
    }
    if (!downloadUrl || !isValidUrl(downloadUrl)) {
      safeToast('error', 'No audio available to download');
      return;
    }
    console.log("AUDIO PLAYER: Downloading from URL:", downloadUrl);
    safeToast('info', 'Preparing download...');
    try {
      const response = await fetch(downloadUrl, {
        method: 'GET',
        headers: { 'Accept': 'audio/mpeg, audio/*', 'Cache-Control': 'no-cache' },
        cache: 'no-store'
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch audio: ${response.status} ${response.statusText}`);
      }
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = 'meditation-' + Date.now() + '.mp3';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      }, 200);
      safeToast('success', 'Downloading your meditation audio');
    } catch (err) {
      console.warn("AUDIO PLAYER: Error using fetch method, trying direct link:", err);
      try {
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'meditation-' + Date.now() + '.mp3';
        a.target = '_blank';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
        }, 200);
        safeToast('success', 'Downloading your meditation audio');
      } catch (directErr) {
        console.error("AUDIO PLAYER: All download methods failed:", directErr);
        safeToast('error', 'Unable to download audio. Please try again.');
      }
    }
  }, [audioUrl, safeToast, onExportAudio, isValidUrl]);

  const handleResetSessionHandler = useCallback(() => {
    if (typeof onReset === 'function') {
      try {
        onReset();
        return;
      } catch (error) {
        console.error("AUDIO PLAYER: Error in provided reset handler:", error);
      }
    }
    try {
      if (waveSurferRef.current) {
        try {
          waveSurferRef.current.pause();
          waveSurferRef.current.destroy();
          waveSurferRef.current = null;
        } catch (e) {
          console.warn("AUDIO PLAYER: Error destroying WaveSurfer:", e);
        }
      }
      if (audioElementRef.current) {
        try {
          audioElementRef.current.pause();
          audioElementRef.current.src = '';
          audioElementRef.current.load();
        } catch (e) {
          console.warn("AUDIO PLAYER: Error cleaning up audio element:", e);
        }
        audioElementRef.current = null;
      }
      if (audioContextRef.current) {
        try {
          audioContextRef.current.close();
        } catch (e) {
          console.warn("AUDIO PLAYER: Error closing AudioContext:", e);
        }
        audioContextRef.current = null;
      }
      analyzerRef.current = null;
      setAnalyzerNode(null);
      if (globalAudioRef.current && typeof globalAudioRef.current.resetAllState === 'function') {
        globalAudioRef.current.resetAllState();
      } else if (typeof window !== 'undefined') {
        window.__ttsKey = null;
        window.__ttsProgress = 0;
        window.__ttsProcessing = false;
        window.__mixedAudioUrl = null;
        window.__currentAudioUrl = null;
        window.__mixingInProgress = false;
        window.__mixingCompleted = false;
        window.__mixingFailed = false;
        try {
          sessionStorage.removeItem('ttsKey');
          sessionStorage.removeItem('ttsProgress');
          sessionStorage.removeItem('ttsProcessing');
          sessionStorage.removeItem('mixedAudioUrl');
          sessionStorage.removeItem('currentAudioUrl');
          sessionStorage.removeItem('fallbackAudioUrl');
          sessionStorage.removeItem('mixingInProgress');
          sessionStorage.removeItem('mixingCompleted');
          sessionStorage.removeItem('mixingFailed');
        } catch (e) {
          console.warn("AUDIO PLAYER: Error clearing session storage:", e);
        }
      }
      blobUrlsRef.current.forEach(url => {
        try {
          URL.revokeObjectURL(url);
        } catch (e) {
          console.warn("AUDIO PLAYER: Error revoking blob URL:", e);
        }
      });
      blobUrlsRef.current = [];
      setAudioUrl('');
      setIsPlaying(false);
      setAudioLoaded(false);
      setIsLoadingAudio(true);
      setCurrentTime(0);
      setDuration(0);
      setIsInitialized(false);
      setInitComplete(false);
      setHasMixedAudio(false);
      setMixReady(false);
      setIsBackgroundTTSProcessing(false);
      setBackgroundTTSProgress(0);
      safeToast('success', 'Session reset. Ready for a new meditation.');
    } catch (error) {
      console.error("AUDIO PLAYER: Error in reset handler:", error);
      safeToast('error', 'Failed to reset session');
    }
  }, [onReset, safeToast]);

  // Global styles
  const getGlobalStyles = useCallback((isMobile) => `
    @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }
    @keyframes pulse { 0% { transform: scale(0.9); opacity: 0.7; } 100% { transform: scale(1.1); opacity: 0.9; } }
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: ${isMobile ? '20px' : '16px'};
      height: ${isMobile ? '20px' : '16px'};
      border-radius: 50%;
      background: #7d12ff;
      cursor: pointer;
      box-shadow: 0 0 6px rgba(125,18,255,0.4);
    }
    input[type="range"]::-moz-range-thumb {
      width: ${isMobile ? '20px' : '16px'};
      height: ${isMobile ? '20px' : '16px'};
      border-radius: 50%;
      background: #7d12ff;
      cursor: pointer;
      border: none;
      box-shadow: 0 0 6px rgba(125,18,255,0.4);
    }
    button:hover:not(:disabled) { transform: translateY(-2px); box-shadow: 0 6px 16px rgba(125,18,255,0.3); }
    button:active:not(:disabled) { transform: translateY(0); }
    @media (max-width: 768px) {
      input[type="range"] { height: 12px; padding: 8px 0; }
      input[type="checkbox"] { width: 20px; height: 20px; }
      select { height: 44px; }
    }
  `, []);

  const loadingAnimationStyles = useCallback(() => `
    .mixing-progress-bar {
      width: 100%;
      height: 4px;
      background-color: rgba(255,255,255,0.1);
      border-radius: 2px;
      overflow: hidden;
      margin-top: 10px;
    }
    .mixing-progress-bar-inner {
      height: 100%;
      width: 0;
      background-color: #7d12ff;
      border-radius: 2px;
      transition: width 0.5s;
    }
    .mixing-progress-bar-animated {
      position: relative;
      animation: indeterminate-progress 1.5s ease-in-out infinite;
      background-image: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
      background-size: 40px 40px;
    }
    @keyframes indeterminate-progress {
      0% { left: -100%; width: 100%; }
      100% { left: 100%; width: 100%; }
    }
  `, []);

  // Setup audio player on mount
  const setupAudioPlayer = useCallback(() => {
    if (!mounted.current) return;
    const validUrl = initialAudioUrl && isValidUrl(initialAudioUrl)
      ? initialAudioUrl
      : (globalAudioRef.current?.getBestAudioUrl?.() || createFallbackAudioBlob());
    if (validUrl) {
      setAudioUrl(validUrl);
      audioUrlRef.current = validUrl;
      const { audioElement } = initializeAudio({
        audioUrl: validUrl,
        globalAudioState: globalAudioRef.current,
        audioElementRef: audioElementRef,
        waveSurferRef: waveSurferRef,
        setAudioElement: (element) => {
          audioElementRef.current = element;
          if (globalAudioRef.current && !globalAudioRef.current._audioElement) {
            globalAudioRef.current._audioElement = element;
          }
        },
        createFallbackElement: () => createFallbackAudioElement(validUrl),
        onAudioReady: handleAudioReady
      });
      if (audioElement && !audioElement._listenersInitialized && setupAudioElementListenersRef.current) {
        setupAudioElementListenersRef.current(audioElement);
        audioElement._listenersInitialized = true;
      }
      // Optionally, initialize analysis after a delay
      setTimeout(() => {
        if (mounted.current && audioElement && !analyzerRef.current && initializeAudioAnalysisRef.current) {
          initializeAudioAnalysisRef.current();
        }
      }, 500);
    }
  }, [mounted, initialAudioUrl, isValidUrl, createFallbackAudioBlob, createFallbackAudioElement, handleAudioReady, globalAudioRef]);


  useEffect(() => {
    mounted.current = true;
    isMountedRef.current = true;
    setComponentReady(true);
    
    console.log("AUDIO PLAYER: Component mounted, initializing...");
    
    // Step 1: Set up references
    setupAudioElementListenersRef.current = setupAudioElementListeners;
    initializeWaveSurferRef.current = safeInitializeWaveSurfer;
    
    // Step 2: Initialize audio using the globalAudioState integration if available
    const initializeWithDelay = () => {
      if (!mounted.current) return;
      
      console.log("AUDIO PLAYER: Initializing audio...");
      
      // First check global audio state
      let bestUrl = null;
      let useGlobalAudio = false;
      
      if (globalAudioRef.current) {
        // Check if there's already a valid audio element
        if (globalAudioRef.current._audioElement) {
          audioElementRef.current = globalAudioRef.current._audioElement;
          useGlobalAudio = true;
          setIsUsingGlobalAudio(true);
          console.log("AUDIO PLAYER: Using global audio element");
        }
        
        // Get best URL
        if (typeof globalAudioRef.current.getBestAudioUrl === 'function') {
          bestUrl = globalAudioRef.current.getBestAudioUrl();
          if (isValidUrl(bestUrl)) {
            console.log("AUDIO PLAYER: Using URL from global audio state:", bestUrl);
            setAudioUrl(bestUrl);
            audioUrlRef.current = bestUrl;
          }
        }
      }
      
      // If no URL from global state, use initialAudioUrl
      if (!bestUrl && initialAudioUrl && isValidUrl(initialAudioUrl)) {
        bestUrl = initialAudioUrl;
        console.log("AUDIO PLAYER: Using initial audio URL:", bestUrl);
        setAudioUrl(bestUrl);
        audioUrlRef.current = bestUrl;
      }
      
      // If still no URL, create silent audio
      if (!bestUrl) {
        bestUrl = createFallbackAudioBlob();
        console.log("AUDIO PLAYER: No valid URL found, using silent audio");
        setAudioUrl(bestUrl);
        audioUrlRef.current = bestUrl;
      }
      
      // Initialize audio element
      const { audioElement } = initializeAudio({
        audioUrl: bestUrl,
        globalAudioState: globalAudioRef.current,
        audioElementRef: audioElementRef,
        waveSurferRef: waveSurferRef,
        setAudioElement: (element) => {
          audioElementRef.current = element;
          if (globalAudioRef.current && !globalAudioRef.current._audioElement) {
            globalAudioRef.current._audioElement = element;
          }
        },
        createFallbackElement: () => createFallbackAudioElement(bestUrl),
        onAudioReady: handleAudioReady
      });
      
      // Set up listeners
      if (audioElement && !audioElement._listenersInitialized && setupAudioElementListenersRef.current) {
        setupAudioElementListenersRef.current(audioElement);
        audioElement._listenersInitialized = true;
      }
      
      // Set initialization flags
      setIsInitialized(true);
      
      // Step 3: Initialize WaveSurfer with a small delay
      setTimeout(() => {
        if (!mounted.current) return;
        
        // This timeout ensures the DOM has settled
        const wsInstance = safeInitializeWaveSurfer();
        
        if (wsInstance && bestUrl) {
          safeLoadAudio(bestUrl, wsInstance);
        }
        
        // Step 4: Initialize audio analysis after a delay
        setTimeout(() => {
          if (!mounted.current) return;
          
          // Only initialize analyzer if audio element exists and no analyzer yet
          if (audioElementRef.current && !analyzerRef.current && initializeAudioAnalysisRef.current) {
            console.log("AUDIO PLAYER: Initializing audio analysis");
            
            try {
              initializeAudioAnalysisRef.current();
            } catch (err) {
              console.warn("AUDIO PLAYER: Error initializing audio analysis:", err);
            }
          }
          
          setInitComplete(true);
        }, 500);
      }, 100);
    };
    
    // Start initialization with a small delay
    audioInitTimeoutRef.current = setTimeout(initializeWithDelay, 100);
    
    // Create a safety timeout
    const safetyTimeout = setTimeout(() => {
      if (mounted.current && !audioLoaded) {
        console.warn("AUDIO PLAYER: Audio load timeout, using fallback");
        
        let fallbackUrl = null;
        
        // Try to get URL from various sources
        if (globalAudioRef.current && typeof globalAudioRef.current.getBestAudioUrl === 'function') {
          fallbackUrl = globalAudioRef.current.getBestAudioUrl();
        }
        
        if (!fallbackUrl && initialAudioUrl) {
          fallbackUrl = initialAudioUrl;
        }
        
        if (!fallbackUrl) {
          fallbackUrl = createFallbackAudioBlob();
        }
        
        createFallbackAudioElement(fallbackUrl);
        setAudioLoaded(true);
        setIsLoadingAudio(false);
      }
    }, 8000);
    
    safetyTimeoutsRef.current.push(safetyTimeout);
    
    // Set up window resize handler
    const handleResize = () => {
      setWindowWidth(window.innerWidth || 0);
      setWindowHeight(window.innerHeight || 0);
    };
    
    window.addEventListener('resize', handleResize);
    
    // Return cleanup function
    return () => {
      console.log("AUDIO PLAYER: Component unmounting, cleaning up resources");
      
      mounted.current = false;
      isMountedRef.current = false;
      
      // Clear timeouts
      if (audioInitTimeoutRef.current) {
        clearTimeout(audioInitTimeoutRef.current);
        audioInitTimeoutRef.current = null;
      }
      
      safetyTimeoutsRef.current.forEach(timeoutId => {
        if (timeoutId) clearTimeout(timeoutId);
      });
      safetyTimeoutsRef.current = [];
      
      // Clean up audio element
      if (audioElementRef.current && !isUsingGlobalAudio) {
        try {
          audioElementRef.current.pause();
          audioElementRef.current.src = '';
          audioElementRef.current.load();
          audioElementRef.current = null;
        } catch (err) {
          console.warn("AUDIO PLAYER: Error cleaning up audio element:", err);
        }
      }
      
      // Clean up audio context
      if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
        try {
          audioContextRef.current.close().then(() => {
            audioContextRef.current = null;
            analyzerRef.current = null;
          }).catch(err => {
            console.warn("AUDIO PLAYER: Error closing AudioContext:", err);
          });
        } catch (err) {
          console.warn("AUDIO PLAYER: Error closing AudioContext:", err);
        }
      }
      
      // Clean up WaveSurfer
      if (waveSurferRef.current) {
        try {
          // Use safe destroy pattern
          if (typeof waveSurferRef.current.safeDestroy === 'function') {
            waveSurferRef.current.safeDestroy();
          } else {
            if (typeof waveSurferRef.current.pause === 'function') {
              waveSurferRef.current.pause();
            }
            if (typeof waveSurferRef.current.destroy === 'function') {
              waveSurferRef.current.destroy();
            }
          }
        } catch (err) {
          console.warn("AUDIO PLAYER: Error destroying WaveSurfer:", err);
        }
        waveSurferRef.current = null;
      }
      
      // Revoke blob URLs
      blobUrlsRef.current.forEach(url => {
        try {
          URL.revokeObjectURL(url);
        } catch (err) {
          console.warn("AUDIO PLAYER: Error revoking blob URL:", err);
        }
      });
      blobUrlsRef.current = [];
      
      // Remove event listeners
      window.removeEventListener('resize', handleResize);
    };
  }, [
    initialAudioUrl, 
    isValidUrl, 
    createFallbackAudioBlob, 
    createFallbackAudioElement, 
    handleAudioReady, 
    globalAudioRef, 
    initializeAudio, 
    safeInitializeWaveSurfer, 
    safeLoadAudio, 
    isUsingGlobalAudio, 
    setupAudioElementListeners
  ]);

  // Mount and cleanup effects
  useEffect(() => {
    mounted.current = true;
    isMountedRef.current = true;
    setComponentReady(true);
    const initTimeout = setTimeout(() => {
      if (mounted.current) {
        setupAudioPlayer();
      }
    }, 100);
    const handleResize = () => {
      setWindowWidth(window.innerWidth || 0);
      setWindowHeight(window.innerHeight || 0);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      clearTimeout(initTimeout);
      window.removeEventListener('resize', handleResize);
      mounted.current = false;
      isMountedRef.current = false;
      if (audioElementRef.current && !isUsingGlobalAudio) {
        try {
          audioElementRef.current.pause();
          audioElementRef.current.src = '';
          audioElementRef.current.load();
          audioElementRef.current = null;
        } catch (err) {
          console.warn("Error cleaning up audio element:", err);
        }
      }
      if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
        audioContextRef.current.close().then(() => {
          audioContextRef.current = null;
          analyzerRef.current = null;
        }).catch(err => {
          console.warn("Error closing AudioContext:", err);
        });
      }
      if (waveSurferRef.current) {
        try {
          waveSurferRef.current.pause();
          waveSurferRef.current.destroy();
          waveSurferRef.current = null;
        } catch (err) {
          console.warn("Error destroying WaveSurfer:", err);
        }
      }
      safetyTimeoutsRef.current.forEach(timeoutId => {
        if (timeoutId) clearTimeout(timeoutId);
      });
      if (audioInitTimeoutRef.current) {
        clearTimeout(audioInitTimeoutRef.current);
      }
      blobUrlsRef.current.forEach(url => {
        try {
          URL.revokeObjectURL(url);
        } catch (err) {
          console.warn("Error revoking blob URL:", err);
        }
      });
    };
  }, [setupAudioPlayer, isUsingGlobalAudio]);

  useEffect(() => {
    if (audioUrl) {
      audioUrlRef.current = audioUrl;
    }
    if (audioElementRef.current && audioUrl && audioElementRef.current.src !== audioUrl) {
      try {
        audioElementRef.current.src = audioUrl;
        audioElementRef.current.load();
      } catch (err) {
        console.warn("Error updating audio element URL:", err);
      }
    }
    if (waveSurferRef.current && audioUrl) {
      safeLoadAudio(audioUrl, waveSurferRef.current);
    }
  }, [audioUrl, safeLoadAudio]);

  useEffect(() => {
    return () => {
      if (window._activeSurfers) {
        Object.keys(window._activeSurfers).forEach(id => {
          try {
            const surfer = window._activeSurfers[id];
            surfer.destroy();
            delete window._activeSurfers[id];
          } catch (err) {
            console.warn("Error cleaning up WaveSurfer instance:", err);
          }
        });
      }
      setWavesurferInitialized(false);
    };
  }, []);

  // (Assume that if you have an external function for setting up audio element listeners, you assign it here.)
  useEffect(() => {
    // For example:
    // setupAudioElementListenersRef.current = mySetupAudioElementListenersFunction;
  }, []);

  useEffect(() => {
    initializeWaveSurferRef.current = initializeWaveSurfer;
  }, [initializeWaveSurfer]);

  // --- Render JSX ---
  const trackInfo = getTrackInfo();

  return (
    <div className="listen-step-container" style={{
      margin: 0,
      padding: 0,
      width: '100%',
      height: isFullscreenMobile ? '100vh' : 'auto',
      position: isFullscreenMobile ? 'fixed' : 'relative',
      top: isFullscreenMobile ? '0' : 'auto',
      left: isFullscreenMobile ? '0' : 'auto',
      zIndex: isFullscreenMobile ? 1000 : 'auto',
      backgroundColor: isFullscreenMobile ? '#161616' : 'transparent',
      transition: 'height 0.3s ease'
    }}>
      {!componentInitialized ? (
        // Loading UI when not initialized yet
        <div className="listen-step-initializing" style={{
          width: '100%',
          height: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0,0,0,0.2)',
          borderRadius: '8px'
        }}>
          <div style={{ textAlign: 'center', color: 'white' }}>
            <div style={{ marginBottom: '10px' }}>Preparing your meditation audio...</div>
            <div style={{
              width: '30px',
              height: '30px',
              margin: '0 auto',
              borderRadius: '50%',
              border: '3px solid rgba(125,18,255,0.3)',
              borderTopColor: '#7d12ff',
              animation: 'spin 1s linear infinite'
            }} />
          </div>
        </div>
      ) : (
        // Main component when initialized
        <>
          <style dangerouslySetInnerHTML={{ __html: getGlobalStyles(isMobile) }} />
          <style dangerouslySetInnerHTML={{ __html: loadingAnimationStyles() }} />
          {(!isFullscreen || !isMobile) && (
            <div className="step-header" style={{ textAlign: 'center', padding: '10px', color: '#fff' }}>
              <h2>Your Meditation is Ready</h2>
              <p>Listen, save, or create a new meditation</p>
            </div>
          )}
          {isFullscreen && (
            <FullScreenVisualizer
              audioElement={audioElementRef.current}
              isPlaying={isPlaying}
              onPlayPause={togglePlayback}
              onToggleFullScreen={toggleFullscreen}
              colorPrimary="#7d12ff"
              colorSecondary="#5636f3"
              backgroundColor="#02073c"
              externalAnalyzer={analyzerRef.current}
              externalAudioContext={audioContextRef.current}
              currentTime={currentTime}
              duration={duration}
              onSkip={handleSkip}
              onSeek={(time) => {
                syncTimePosition({
                  currentTime: time,
                  audioElement: audioElementRef.current,
                  waveSurfer: waveSurferRef.current
                });
              }}
              musicLibrary={musicLibrary}
              selectedMusic={selectedMusic}
              onMusicSelect={handleMusicSelect}
            />
          )}
          <div className="music-player-mini" style={{
            width: '100%',
            maxWidth: '800px',
            margin: '24px auto',
            padding: '20px',
            backgroundColor: '#1C1C2E',
            backgroundImage: 'linear-gradient(135deg, #1E1C2E 0%, #0C0C14 100%)',
            borderRadius: '20px',
            boxShadow: '0 10px 30px rgba(0,0,0,0.3)',
            transition: 'all 0.3s ease'
          }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '20px' }}>
              <div style={{ position: 'relative', width: '80px', height: '80px', borderRadius: '50%', flexShrink: 0, overflow: 'hidden', backgroundColor: '#02073c' }}>
                {renderVisualizer()}
                <button onClick={toggleVisualizer} style={{
                  position: 'absolute',
                  bottom: '4px',
                  right: '4px',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  color: 'white',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '10px',
                  zIndex: 10
                }}>
                  <IoImage size={12} />
                </button>
              </div>
              <div style={{ flex: 1, minWidth: 0, color: '#fff' }}>
                <h3 style={{ fontSize: '18px', fontWeight: '600', marginBottom: '8px' }}>{trackInfo.title}</h3>
                <p style={{ fontSize: '14px', color: 'rgba(255,255,255,0.7)', margin: 0 }}>{trackInfo.artist}</p>
              </div>
              {isMobile && (
                <button onClick={toggleFullscreen} style={{
                  background: 'linear-gradient(135deg, #7d12ff 0%, #5636f3 100%)',
                  border: 'none',
                  borderRadius: '50%',
                  width: '44px',
                  height: '44px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  boxShadow: '0 4px 10px rgba(125,18,255,0.3)',
                  color: '#fff'
                }}>
                  {isFullscreen ? <IoContract size={24} /> : <IoExpand size={24} />}
                </button>
              )}
            </div>
  
            {/* Waveform container */}
            <div style={{
              marginBottom: '10px',
              position: 'relative',
              backgroundColor: 'rgba(20,20,30,0.3)',
              borderRadius: '8px',
              padding: '5px',
              overflow: 'hidden',
              minHeight: '100px'
            }}>
              <div ref={waveformContainerRefCallback} style={{
                marginBottom: '5px',
                minHeight: '80px',
                height: '80px',
                width: '100%',
                position: 'relative'
              }} onClick={handleScrubberClick} />
              <div ref={timelineContainerRef} style={{ height: '30px', width: '100%' }} />
              {componentReady && !wavesurferInitialized && (
                <ResponsiveWaveSurfer
                  audioUrl={audioUrl}
                  isPlaying={isPlaying}
                  onReady={handleAudioReady}
                  onError={handleAudioError}
                  onTimeUpdate={handleTimeUpdate}
                  zoom={100} // Fixed zoom level
                  height={80}
                  waveColor="rgba(255, 255, 255, 0.6)" // White for unplayed sections
                  progressColor="#7d12ff" // Purple for played sections
                  showTimeline={true}
                  onPositionChange={handlePositionChange}
                  containerRef={waveformContainerRef}
                  timelineContainerRef={timelineContainerRef}
                  volume={volume}
                  muted={isMuted}
                  globalAudioRef={globalAudioRef}
                />
              )}
              {!waveSurferRef.current && !isLoadingAudio && (
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: duration > 0 ? `${(currentTime / duration) * 100}%` : '0%',
                  backgroundColor: '#7d12ff',
                  borderRadius: '4px 0 0 4px',
                  transition: 'width 0.1s linear',
                  zIndex: 2
                }} />
              )}
              {isLoadingAudio && (
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '4px',
                  backgroundColor: 'rgba(0,0,0,0.1)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 10
                }}>
                  <div style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    border: '3px solid rgba(125,18,255,0.3)',
                    borderTopColor: '#7d12ff',
                    animation: 'spin 1s linear infinite'
                  }} />
                </div>
              )}
            </div>
            {/* Playback controls */}
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: '20px' }}>
              <button onClick={() => handleSkip(-10)} disabled={!audioLoaded} style={{
                backgroundColor: 'rgba(255,255,255,0.05)',
                border: 'none',
                borderRadius: '50%',
                width: '44px',
                height: '44px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                color: '#fff',
                opacity: audioLoaded ? 1 : 0.5
              }}>
                <IoPlaySkipBack size={24} />
              </button>
              <button onClick={togglePlayback} disabled={!audioLoaded} style={{
                background: 'linear-gradient(135deg, #7d12ff 0%, #5636f3 100%)',
                border: 'none',
                borderRadius: '50%',
                width: '60px',
                height: '60px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                boxShadow: '0 6px 15px rgba(125,18,255,0.3)',
                color: '#fff',
                opacity: audioLoaded ? 1 : 0.5
              }}>
                {isPlaying ? <IoPauseCircle size={32} /> : <IoPlayCircle size={32} />}
              </button>
              <button onClick={() => handleSkip(10)} disabled={!audioLoaded} style={{
                backgroundColor: 'rgba(255,255,255,0.05)',
                border: 'none',
                borderRadius: '50%',
                width: '44px',
                height: '44px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                color: '#fff',
                opacity: audioLoaded ? 1 : 0.5
              }}>
                <IoPlaySkipForward size={24} />
              </button>
              <button onClick={toggleMute} disabled={!audioLoaded} style={{
                backgroundColor: 'rgba(255,255,255,0.05)',
                border: 'none',
                borderRadius: '50%',
                width: '44px',
                height: '44px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                color: '#fff',
                opacity: audioLoaded ? 1 : 0.5
              }}>
                {isMuted ? <IoVolumeOff size={24} /> : <IoVolumeHigh size={24} />}
              </button>
            </div>
            {!isMobile && (
              <div style={{ display: 'flex', alignItems: 'center', gap: '12px', marginBottom: '20px', padding: '0 16px' }}>
                <IoVolumeOff size={16} style={{ color: 'rgba(255,255,255,0.6)' }} />
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={volume}
                  onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
                  style={{
                    flex: 1,
                    height: '4px',
                    appearance: 'none',
                    backgroundColor: 'rgba(255,255,255,0.1)',
                    outline: 'none',
                    borderRadius: '2px',
                    background: `linear-gradient(to right, #7d12ff 0%, #7d12ff ${volume * 100}%, rgba(255,255,255,0.1) ${volume * 100}%, rgba(255,255,255,0.1) 100%)`
                  }}
                />
                <IoVolumeHigh size={16} style={{ color: 'rgba(255,255,255,0.6)' }} />
              </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '12px' }}>
              <button onClick={togglePlaylist} style={{
                flex: 1,
                padding: '12px',
                backgroundColor: 'rgba(255,255,255,0.05)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                color: 'rgba(255,255,255,0.8)',
                fontSize: '14px',
                fontWeight: '500',
                cursor: 'pointer'
              }}>
                <IoList size={18} />
                <span>Playlist</span>
              </button>
              <button onClick={handleExportAudioHandler} style={{
                flex: 1,
                padding: '12px',
                backgroundColor: 'rgba(255,255,255,0.05)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                color: 'rgba(255,255,255,0.8)',
                fontSize: '14px',
                fontWeight: '500',
                cursor: 'pointer'
              }}>
                <IoDownload size={18} />
                <span>Download</span>
              </button>
              {typeof onReset === 'function' && (
                <button onClick={handleResetSessionHandler} style={{
                  flex: 1,
                  padding: '12px',
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  border: '1px solid rgba(255,255,255,0.1)',
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  color: 'rgba(255,255,255,0.8)',
                  fontSize: '14px',
                  fontWeight: '500',
                  cursor: 'pointer'
                }}>
                  <IoRefresh size={18} />
                  <span>New</span>
                </button>
              )}
            </div>
          </div>
          {showPlaylist && (
            <div style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0,0,0,0.8)',
              zIndex: 2000,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '20px'
            }}>
              <div style={{
                width: '100%',
                maxWidth: '500px',
                maxHeight: '80vh',
                backgroundColor: '#1C1C2E',
                borderRadius: '16px',
                boxShadow: '0 10px 30px rgba(0,0,0,0.4)',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column'
              }}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '16px 20px',
                  borderBottom: '1px solid rgba(255,255,255,0.1)'
                }}>
                  <h3 style={{ margin: 0, color: '#fff', fontSize: '18px' }}>Select Background Music</h3>
                  <button onClick={togglePlaylist} style={{
                    background: 'transparent',
                    border: 'none',
                    color: 'white',
                    cursor: 'pointer',
                    padding: '4px'
                  }}>
                    <IoClose size={24} />
                  </button>
                </div>
                <div style={{ overflowY: 'auto', padding: '10px', flex: 1 }}>
                  {Array.isArray(musicLibrary) && musicLibrary.length > 0 ? (
                    musicLibrary.map((track, index) => {
                      if (!track || !track.name) return null;
                      const displayName = track.displayName || track.name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
                      const isActive = track.name === selectedMusic;
                      const trackMood = getMoodLabel(track.name);
                      const coverArt = getAlbumArt(track.name);
                      return (
                        <div key={track.name || index} onClick={() => handleMusicSelect(track.name)} style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '12px',
                          borderRadius: '8px',
                          marginBottom: '8px',
                          cursor: 'pointer',
                          backgroundColor: isActive ? 'rgba(125,18,255,0.15)' : 'transparent',
                          transform: isActive ? 'translateY(-1px)' : 'none',
                          transition: 'all 0.2s ease'
                        }}>
                          <div style={{
                            width: '48px',
                            height: '48px',
                            borderRadius: '8px',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            marginRight: '12px',
                            backgroundImage: `url(${coverArt})`
                          }}></div>
                          <div style={{ flex: 1, overflow: 'hidden' }}>
                            <div style={{
                              fontSize: '16px',
                              fontWeight: isActive ? '600' : '500',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              color: '#ffffff'
                            }}>{displayName}</div>
                            <div style={{
                              fontSize: '13px',
                              color: 'rgba(255,255,255,0.6)',
                              marginTop: '2px'
                            }}>{trackMood}</div>
                          </div>
                          {isActive && (
                            <div style={{
                              width: '6px',
                              height: '24px',
                              backgroundColor: '#7d12ff',
                              borderRadius: '3px',
                              marginLeft: '12px'
                            }}></div>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '40px 20px',
                      color: 'rgba(255,255,255,0.7)',
                      textAlign: 'center'
                    }}>
                      <div style={{ fontSize: '16px', marginBottom: '10px' }}>No music tracks available</div>
                      <button onClick={togglePlaylist} style={{
                        marginTop: '10px',
                        padding: '8px 16px',
                        borderRadius: '8px',
                        background: 'rgba(125,18,255,0.2)',
                        border: '1px solid rgba(125,18,255,0.4)',
                        color: 'white',
                        cursor: 'pointer'
                      }}>Close</button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <AudioDiagnosticsTool 
            audioUrl={audioUrl} 
            analyzerNode={analyzerRef.current} 
            audioElement={audioElementRef.current}
          />
        </>
      )}
    </div>
  );
};

export default ListenStep;
